


































































































































































































































































































































































































































































// tr {
//   #edit-button {
//     opacity: 0;
//   }
// }

// tr:hover {
//   #edit-button {
//     opacity: 100;
//   }
// }
#wrapper {
  overflow: hidden;
  background: #ccc;
  transition: height 200ms;
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.active .guest-name::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}
.checkin-modal {
  .vs-dialog {
    position: relative;
    width: 25%;
    min-width: 400px;
    max-width: 800px;
    height: 25%;
    margin: auto;
    color: rgba(var(--vs-text), 1);
    background: rgba(var(--vs-background), 1);
    border-radius: var(--vs-radius);
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, var(--vs-shadow-opacity));
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, var(--vs-shadow-opacity));
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .vs-dialog__content {
    position: relative;
    width: 100%;
    padding: 10px 16px;
    border-radius: inherit;
  }
}
.table-row.table-row {
  max-height: 650px;
  padding: 10px;
  background-color: #fafafa;
  transition: all 500ms ease;
}

.table-row {
  max-height: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: transparent;
}
