








































































































































































































































































































































// tr {
//   #action-buttons {
//     opacity: 0;
//   }
// }
// tr:hover {
//   #action-buttons {
//     opacity: 100;
//   }
// }
.w100 {
  min-width: 100px !important;
  max-width: 100px !important;
}
