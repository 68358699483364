































































































































































































































































































































































































































.list-item {
  display: inline-block;
  //   margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}
