<script lang="ts">
/* eslint-disable no-param-reassign */
import { Component, Emit, PropSync, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import { IBookingDto } from '@/app/entities';
import dayjs from '@/app/utils/date';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { guestModule } from '@/store/modules/guest.module';
import userModule from '@/store/modules/user/user.module';
import Accordion from '@/components/accordion/Accordion.vue';
import { BookingStatus } from '@/app/dto/bookingDto';
import attachment from '@/components/attachment/attachment.vue';
import Guest from '@/app/entities/tenant/guest.dto';
import Indemnity from '@/app/entities/tenant/Indemnity.dto';
import isEmpty from '../../../app/utils/object.utils';
import * as _ from 'lodash';
import { Loading } from '@/modules/auth/store/auth.service';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { POSITION } from 'vue-toastification';

interface minorParentObj {
  name?: string;
  surname?: string;
  parentName?: string;
  parentSurname?: string;
  parent: any;
}

@Component({
  components: {
    Accordion,
    attachment,
  },
})
export default class BookingAddModal extends mixins(SchemaMixin) {
  @PropSync('modalFlag') modalFlagSync: boolean;

  @PropSync('editBooking') editBookingSync: IBookingDto;

  $refs!: any;

  minor = [];

  selected = 0;

  counterGuest = 1;

  initialGuests = 0;

  booking: IBookingDto = {} as IBookingDto;

  guest: Guest[] = [];

  initialMinors = 0;

  indemnity: Indemnity[] = [];
  editSelected = false;

  minors = [] as minorParentObj[];

  get guests() {
    return this.guest;
  }

  set guests(v: any) {
    this.guest = v;
  }

  get registeredMinorTotal() {
    if (this.minors) return this.minors.length;
    return 0;
  }

  get bookingEdit() {
    return this.editBookingSync !== null;
  }

  get guestEdit() {
    const isTheSame = _.isEqual(this.booking.guest, this.guest);
    return this.editBookingSync !== null && !isTheSame;
  }

  async created() {
    if (this.editBookingSync) {
      this.editSelected = true;
      this.editBookingSync.dates = {
        checkInDate: this.editBookingSync.checkInDate,
        checkOutDate: this.editBookingSync.checkOutDate,
      };
      this.booking = _.cloneDeep(this.editBookingSync);
      if (this.booking.guest) {
        this.booking.guest.sort((x, y) => {
          return x.isPrimary == true ? -1 : y.isPrimary == true ? 1 : 0;
        });
      }
      this.guest = _.cloneDeep(this.booking.guest) as Guest[];
      this.counterGuest = this.guest.length;
      this.initialGuests = this.guest.length;
      this.editBookingSync.guest.forEach((guest) => {
        this.initialMinors += guest.minor.length;
      });

      // this.initialMinors = this.booking.numberOfMinors;
    } else {
      this.booking = {
        numberOfMinors: 0,
        numberOfGuests: 0,
        dates: {
          checkInDate: '',
          checkOutDate: '',
        },
      } as IBookingDto;
      const guest = new Guest();
      guest.status = BookingStatus.PENDING;
      guest.isPrimary = true;
      this.guest.push(guest);
    }
    if (Object.keys(tenantModule?.accommodations).length === 0) {
      await tenantModule.getAccommodations();
    }

    this.minors = this.booking.guest?.flatMap((x) => {
      return x.minor.map((y) => {
        return { parent: x.firstName, parentSurname: x.lastName, name: y.firstName, surname: y.lastName };
      });
    });
  }
  customFormatter(date: any) {
    return dayjs(date, 'DD-MM-YYYY');
  }

  get selectedAccommodation() {
    if (this.editBookingSync && this.editSelected) {
      this.indemnity = [];
      this.editSelected = false;
      this.selected = this.accommodationList.findIndex((accom) => accom == this.booking.accommodation?.name);
      const key = parseInt(this.selected as any, 10);
      this.$nextTick(() => {
        this.booking?.indemnity?.forEach((indemnity) => {
          tenantModule.accommodations[key].indemnity.forEach((accIndem) => {
            if (indemnity.name == accIndem.name) {
              this.selectIndemnity(accIndem);
            }
          });
        });
      });

      return tenantModule.accommodations[key].indemnity;
    }
    const key = parseInt(this.selected as any, 10);
    this.indemnity = [];
    return tenantModule.accommodations[key].indemnity;
  }

  get accommodationList(): (string | undefined)[] {
    return tenantModule.accommodations.map((acc: any) => acc.name);
  }

  get mapBooking() {
    return {
      accommodationId: tenantModule.accommodations[this.selected].id,
      numberOfGuests: this.guest?.length as number,
      checkInDate: this.booking.dates.checkInDate,
      checkOutDate: this.booking.dates.checkOutDate,
      invitations: this.guest.length - this.guest.filter((val) => val.email !== null).length,
      minorInvitations: this.booking.numberOfMinors,
      tenantId: tenantModule.tenant.id,
      indemnity: this.indemnity as any,
    };
  }

  addGuest(): void {
    if (this.booking.guest) {
      const guest = new Guest();
      guest.status = BookingStatus.PENDING;
      this.guest.push(guest);
    }
  }

  removeGuest(): void {
    if (this.booking.guest) {
      this.booking.guest.pop();
    }
  }

  closeModal(): void {
    this.modalFlagSync = false;
    this.clear();
  }

  hasIndemnity(v: any) {
    return this.indemnity.includes(v);
  }

  selectIndemnity(indemnity: any) {
    if (this.hasIndemnity(indemnity)) {
      const index = this.indemnity.indexOf(indemnity);
      this.indemnity.splice(index, 1);
    } else {
      this.indemnity.push(indemnity);
    }
  }

  async updateBooking() {
    this.booking.accommodationId = tenantModule.accommodations[this.selected].id as string;
    this.booking.numberOfGuests = this.guest?.length as number;
    this.booking.checkInDate = this.booking.dates.checkInDate;
    this.booking.checkOutDate = this.booking.dates.checkOutDate;
    this.booking.invitations = this.guest.length - this.guest.filter((val) => val.email !== null).length;
    this.booking.minorInvitations = this.booking.numberOfMinors - this.initialMinors;
    this.booking.tenantId = tenantModule.tenant.id;
    this.booking.indemnity = this.indemnity as any;
    this.booking.credits = this.counterGuest - this.initialGuests;
    this.booking.bookingReference = this.booking.bookingReference?.toUpperCase();
    await guestModule.createBooking(this.booking);
    this.closeModal();
    await guestModule.findAllBookings().then(() => {
      this.$swal('Updated!', 'Your booking has been updated.', 'success');
    });
  }

  checkAllGuests() {
    let checkForEmpties = true;
    const guestFormStatus = this.guests.every((_guest: Guest, index: number) => {
      this.$refs[`ref-${index}`].formSubmitted();
      if (this.$refs[`ref-${index}`].hasErrors || (isEmpty(this.guest[index].email) && isEmpty(this.guest[index].phone?.number)))
        return false;
      return true;
    });
    if (this.guest[0].status !== 'PENDING') checkForEmpties = false;
    if (!guestFormStatus && !checkForEmpties) {
      this.$swal({
        title: 'Some guest details are missing',
        text: 'Please complete the guest details',
        icon: 'error',
        showConfirmButton: true,
      });
      return false;
    }
    return true;
  }

  checkPrimaryGuest() {
    this.$refs['ref-0'].formSubmitted();
    if (isEmpty(this.guest[0].email) && isEmpty(this.guest[0].phone?.number)) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: `Please fill in either phone number or email before inviting guest`,
            variant: 'error',
          },
        },
        {
          position: POSITION.TOP_RIGHT,
        },
      );
      return false;
    }
    if (this.$refs['ref-0'].hasErrors) return false;
    return true;
  }

  async checkBooking() {
    if (this.$refs['ref-main'].hasErrors) {
      this.$swal({
        title: 'Some booking details are missing',
        text: 'Please fill out all the booking details',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }
    if (!this.indemnity.length) {
      this.$swal({
        title: 'No Indemnities',
        text: 'Please select an indemnity for the booking',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }

    if (this.bookingEdit && !this.guestEdit) {
      return this.updateBooking();
    }

    if (this.guestEdit) {
      if (!this.checkAllGuests()) return;
    } else {
      if (!this.checkPrimaryGuest()) return;
    }

    if (this.bookingEdit) {
      return this.saveBooking();
    }
    let isRefMatch = false;
    Object.values(guestModule.bookings).forEach((obj) => {
      if (obj.bookingReference === this.booking.bookingReference?.toUpperCase()) {
        return (isRefMatch = true);
      }
    });
    if (isRefMatch) {
      this.$swal({
        title: 'Duplicate Booking Reference',
        text: 'A booking with that reference already exists',
        icon: 'error',
        showConfirmButton: true,
      });
      return;
    }
    this.saveBooking();
  }

  @Loading
  async saveBooking(): Promise<void> {
    this.booking.accommodationId = tenantModule.accommodations[this.selected].id as string;
    this.booking.numberOfGuests = this.guest?.length as number;
    this.booking.checkInDate = this.booking.dates.checkInDate;
    this.booking.checkOutDate = this.booking.dates.checkOutDate;
    this.booking.invitations = this.guest.length - this.guest.filter((val) => val.email !== null).length;
    this.booking.minorInvitations = this.booking.numberOfMinors - this.initialMinors;
    this.booking.tenantId = tenantModule.tenant.id;
    this.booking.indemnity = this.indemnity as any;
    this.booking.credits = this.counterGuest - this.initialGuests;
    this.booking.bookingReference = this.booking.bookingReference?.toUpperCase();

    const booking = await guestModule.createBooking(this.booking);

    if (booking) {
      this.$gtm.trackEvent({
        event: 'Booking', // Event type [default = 'interaction'] (Optional)
        // category: 'testing', // Dunno
        action: 'created',
        type: 'created', // Event Action
        accommodation: tenantModule.accommodations[this.selected].id, // Accommodation ID
        accom_name: tenantModule.accommodations[this.selected].name, // Accommodation name
        // org: guestModule.organisationId,
        noninteraction: true, // Optional
      });
    }
    const guests = this.guest
      .filter((guest) => guest.status === 'PENDING')
      .map<Guest>((guest, index) => {
        guest.booking = booking;
        guest.mobileNumber = guest.mobileNumber?.replace(/^0+/, '');
        if (this.editBookingSync === null) {
          guest.isPrimary = index === 0 ? true : false;
        }
        return guest;
      });

    await guestModule.createGuest({ guests, booking });

    // eslint-disable-next-line vue/custom-event-name-casing
    if (booking && userModule.user?.id) {
      tenantModule.getTenant();
    }

    guestModule.findAllBookings().then(() => {
      this.$swal('Saved!', 'Your booking has been saved.', 'success');
    });
    this.closeModal();
  }
  destroyed() {
    this.editBookingSync = {} as IBookingDto;
  }
  @Watch('counterGuest')
  counterHandler(event: number) {
    if (event > this.guest.length) {
      const guest = new Guest();
      guest.status = 'PENDING';
      this.guest.push(guest);
    } else if (event < this.guest.length) {
      this.$nextTick(() => {
        this.guest.pop();
      });
    }
    this.$nextTick(() => {
      this.$emitter.emit('updatePanels', event);
    });
  }
  remo() {
    if (this.booking.guest) {
      this.guest.pop();
    }
  }

  get bookingForm() {
    return this.booking.guest;
  }

  @Emit('refresh')
  refresh() {
    return true;
  }
  @Emit('clear')
  clear() {
    return true;
  }

  async deleteBooking() {
    const response = await this.$swal({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      position: 'center',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });

    if (response.isConfirmed) {
      let status: any[] = [];
      this.editBookingSync.guest.forEach((guest) => {
        status.push(guest.status);
      });
      if (status.includes('CHECKEDIN')) {
        this.$swal(`You can't do that`, `You can't delete a booking with checked in guests.`, 'error');
      } else {
        guestModule.deleteBooking(this.editBookingSync).then(() => {
          this.$swal('Deleted!', 'Your booking has been deleted.', 'success');
          this.editBookingSync = {} as IBookingDto;
          this.refresh();
          this.closeModal();
        });
      }
    }
  }
}
</script>

<template>
  <div label="Guest details" class="add-booking-form schema p-6 dark:bg-gray-700">
    <FormulateForm :ref="`ref-main`">
      <div class="grid grid-cols-12 gap-4 dark:bg-gray-700">
        <div class="col-span-8 px-4 pt-2 pb-5 order-last md:order-1 dark:bg-gray-700">
          <!-- <portal to="kids">
            <template v-for="i in booking.numberOfMinors">
              <font-awesome-icon :key="i" icon="child"></font-awesome-icon>
            </template>
          </portal> -->
          <Accordion v-if="guest.length > 0" :data="guest" :counter-guest="counterGuest" class="dark:bg-gray-600">
            <template #ind="{ ind }">
              <FormulateForm
                v-if="ind <= guests.length"
                :key="`form-${ind}`"
                :ref="`ref-${ind}`"
                v-model="guests[ind]"
                class="dark:bg-gray-700"
                :schema="guestSchema(guests[ind])"
              />
            </template>
          </Accordion>
          <Accordion v-if="booking.numberOfMinors > 0" :counter-guest="booking.numberOfMinors" class="mt-4 dark:bg-gray-600">
            <template #ind>
              <template v-if="booking.numberOfMinors">
                <template v-for="(minor, i) in minors">
                  <div :key="i" class="col-span-4 sm:col-span-3">
                    <div class="flex gap-4 rounded-md">
                      <div class="w-1/2">
                        <h3 class="text-sm font-medium">Minor Name</h3>
                        {{ minor.name + ' ' + minor.surname }}
                      </div>
                      <div :key="i" class="w-1/2">
                        <h3 class="text-sm font-medium">Parent</h3>
                        {{ minor.parent + ' ' + minor.parentSurname }}
                      </div>
                    </div>
                    <div aria-hidden="true">
                      <div class="py-4">
                        <div class="border-t border-gray-200"></div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-for="i in booking.numberOfMinors - registeredMinorTotal">
                  <div :key="i" class="col-span-4 sm:col-span-3">
                    <div class="flex gap-4 rounded-md">
                      <div class="w-1/2">
                        <h3 class="text-sm font-medium">Minor Name</h3>
                        Minor {{ i }}
                      </div>
                      <div :key="i" class="w-1/2">
                        <h3 class="text-sm font-medium">Parent</h3>
                        No parent assigned
                      </div>
                    </div>
                    <div aria-hidden="true">
                      <div class="py-4">
                        <div class="border-t border-gray-200"></div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </Accordion>
        </div>
        <div class="col-span-4">
          <div class="px-4 pt-2 pb-5 bg-white dark:bg-gray-700">
            <FormulateInput
              v-model="selected"
              :options="{ ...accommodationList }"
              type="select"
              placeholder="Select an option"
              label="Accommodation"
              class="dark:text-black"
              :input-class="['dark:bg-gray-500 dark:text-white']"
            />

            <div class="inline-flex w-full">
              <FormulateInput
                v-model="booking.dates"
                type="datepicker"
                class="w-full"
                label="Booking dates"
                validation="checkIn|checkOut"
                :validation-rules="{
                  checkIn: ({ value }) => value.checkInDate != '',
                  checkOut: ({ value }) => value.checkOutDate != '',
                }"
                name="checkInDate"
                date-two="checkOutDate"
                :validation-messages="{
                  checkIn: 'Please select the booking dates',
                  checkOut: 'Please select the booking dates',
                }"
              />
            </div>
            <div class="flex">
              <FormulateInput
                v-model="counterGuest"
                head="Adults"
                sub="18+"
                type="counter"
                name="numberOfGuests"
                validation="min:1,value"
              />
            </div>
            <div class="flex">
              <FormulateInput v-model="booking.numberOfMinors" head="Minors" sub="Under 18" type="counter" name="numberOfMinors" />
            </div>
            <div>
              <section>
                <FormulateInput
                  v-model="booking.bookingReference"
                  type="text"
                  name="Booking Reference"
                  label="Booking Reference"
                  validation="required"
                  v-tooltip.top="{
                    content: 'A unique ID of your choosing',
                  }"
                />
              </section>
            </div>
            <label>Select which terms to send to your guests:</label>
            <template v-if="selectedAccommodation">
              <template v-for="(acc, index) in selectedAccommodation">
                <div :key="index" class="cursor-pointer" @click="selectIndemnity(acc)">
                  <attachment :data.sync="selectedAccommodation[index]">
                    <template #icon>
                      <font-awesome-icon v-if="!hasIndemnity(acc)" icon="exclamation-circle" class="text-red-500"></font-awesome-icon>
                      <font-awesome-icon v-else icon="check" class="text-teal-500"></font-awesome-icon>
                    </template>

                    <template #content>
                      <span
                        class="font-medium text-red-600 hover:text-red-500 transition duration-150 ease-in-out flex"
                        :class="{ 'text-teal-500 hover:text-teal-500': hasIndemnity(acc) }"
                      >
                        <div
                          v-if="hasIndemnity(acc)"
                          class="border border-gray-200 w-5 h-5 flex justify-center ml-2"
                          :class="{ 'border-teal-500': hasIndemnity(acc) }"
                        >
                          <font-awesome-icon icon="check" class="text-teal-500 border-teal-500 pt-1"></font-awesome-icon
                        ></div>
                        <div v-else class="border border-gray-200 w-5 h-5 ml-2"></div>
                      </span>
                    </template>
                  </attachment>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <button
          v-if="editBookingSync"
          id="deleteBookingBtn"
          class="white-button-outline-gray text-base"
          style="margin-top: 21px"
          @click="deleteBooking(), clear()"
        >
          <div style="margin-top: 2px"> Delete </div>
        </button>
        <div class="ml-auto flex">
          <cib-button v-if="guestEdit" id="updateSendBtn" color="red" class="px-2" style="margin-top: 21px" @clicked="checkBooking">
            <template #text>Update Booking & Send Invitations </template>
          </cib-button>
          <cib-button v-if="!guestEdit" id="sendInviteBtn" color="red" class="px-2" style="margin-top: 21px" @clicked="checkBooking">
            <template #text>{{ bookingEdit ? 'Update Booking' : 'Send Invitation' }} </template>
          </cib-button>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<style lang="css">
.badger-accordion-panel {
  background-color: white !important;
}
</style>
